import { Cart } from '@medusajs/medusa';
import { Alert, Button } from 'antd';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';

import { useCart } from '../../hooks/use-cart';
import useCheckout from '../../hooks/useCheckout';
import { formatPrice } from '../../utils/format-price';
import Card, { CardBody, CardHeader } from '../ui/Card';
import OrderItemList from './OrderItemList';

export type InventoryError = { sku: string; quantity?: number };

const OrderSummary = () => {
  const {
    cart,
    actions: { setVisible, completeCart },
  } = useCart();
  const { processShippingAddress } = useCheckout();

  const [error, setError] = useState<React.ReactNode>();
  const [inventoryError, setInventoryError] = useState<InventoryError[]>([]);
  const [loading, setLoading] = useState(false);

  const onCheckout = async () => {
    setLoading(true);
    try {
      const shippingAddress = await processShippingAddress();
      if (!shippingAddress) {
        throw new Error('No shipping address selected');
      }

      const order = await completeCart(null, shippingAddress);

      navigate(`/checkout-success?orderId=${order?.display_id}`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errCode = err.response?.data.code;

        switch (errCode) {
          case 'insufficient_inventory':
            setError(
              'Stock Unavailable. The quantity you selected exceeds our current stock. Please adjust the quantity in your cart to proceed with your purchase.'
            );
            setInventoryError(err.response?.data.errors);
            break;

          case 'invalid_payment_type':
            setError(
              'บัญชีของคุณไม่สามารถยืนยันคำสั่งซื้อได้ กรุณาติดต่อแอดมินเพื่อตั้งค่าช่องทางการชำระเงินกับบัญชีของคุณ'
            );
            break;

          case 'invalid_dynamic_365_customer_id':
            setError(
              <>
                บัญชีของคุณไม่สามารถยืนยันคำสั่งซื้อได้ กรุณาติดต่อ
                <span className="whitespace-nowrap">แอดมิน</span>
                เพื่อผูกหมายเลขลูกค้ากับบัญชีของคุณ
              </>
            );
            break;

          default:
            break;
        }
      } else {
        setError(
          'ขออภัย พบปัญหาในการสั่งซื้อสินค้า กรุณาตรวจสอบข้อมูลของคุณและลองใหม่อีกครั้ง'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div className="text-light-title">สรุปคำสั่งซื้อ</div>
          <Link
            to="/cart"
            onClick={(e) => {
              if (window.screen.width >= 768) {
                e.stopPropagation();
                e.preventDefault();
                setVisible(true);
              }
            }}
          >
            แก้ไข
          </Link>
        </div>
      </CardHeader>
      <CardBody className="flex flex-col gap-4">
        <OrderItemList inventoryError={inventoryError} />
        <OrderTotal cart={cart} />
        <Alert
          message="Only products with price has been calculated."
          type="info"
          showIcon
        />
        <Button
          loading={loading}
          type="primary"
          onClick={onCheckout}
          block
          size="large"
        >
          ยืนยันคำสั่งซื้อ
        </Button>
        {error && <Alert message={error} type="error" showIcon />}
      </CardBody>
    </Card>
  );
};

const OrderTotal = ({ cart }: { cart: Cart }) => {
  return (
    <div className="flex flex-col gap-1 text-light-title">
      <div className="flex justify-between">
        <div className="font-medium text-light-secondary">Subtotal</div>
        <div>฿ {formatPrice(cart.subtotal || 0)}</div>
      </div>
      <div className="flex justify-between">
        <div className="font-medium text-light-secondary">Shipping</div>
        <div>฿ {formatPrice(cart.shipping_total || 0)}</div>
      </div>
      <div className="flex justify-between">
        <div className="font-medium text-light-secondary">Total</div>
        <div>
          <span className="font-bold text-light-secondary">THB</span> ฿{' '}
          {formatPrice(cart.total || 0)}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
