import { Button, Checkbox, Form } from 'antd';
import React, { useState } from 'react';

import useCheckout from '../../hooks/useCheckout';
import PlusIcon from '../../icons/plus';
import AddressForm from '../account/address/AddressForm';
import NewAddressFormModal from '../account/address/NewAddressFormModal';
import Card, { CardBody, CardHeader } from '../ui/Card';
import DeliveryAddresOptionList from './DeliveryAddressOptionList';

const DeliveryAddressSection = () => {
  const { newAddressForm, addresses } = useCheckout();

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          Delivery Address{addresses.length < 5 && <NewAddressAction />}
        </div>
      </CardHeader>
      <CardBody>
        {addresses.length > 0 ? (
          <DeliveryAddresOptionList />
        ) : (
          <div className="-mb-2">
            <Form form={newAddressForm} layout="vertical" noValidate>
              <AddressForm hideDefaultOption />
              <Form.Item
                initialValue={true}
                name="save_address"
                valuePropName="checked"
              >
                <Checkbox>Save this address for next time</Checkbox>
              </Form.Item>
            </Form>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const NewAddressAction = () => {
  const [isNewAddressFormOpen, setIsNewAddressFormOpen] = useState(false);
  const { setSelectedShippingAddress } = useCheckout();

  return (
    <>
      <Button
        icon={<PlusIcon />}
        className="!flex gap-x-1 !text-primary-6"
        size="small"
        onClick={() => setIsNewAddressFormOpen(true)}
      >
        เพิ่มที่อยู่จัดส่ง
      </Button>
      <NewAddressFormModal
        open={isNewAddressFormOpen}
        setOpen={(open: boolean) => setIsNewAddressFormOpen(open)}
        onSuccess={(address) => setSelectedShippingAddress(address)}
      />
    </>
  );
};

export default DeliveryAddressSection;
